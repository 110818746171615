import React from 'react'
import ServicesHero from '../functions/ServicesHero'
import ServicesSection from '../functions/ServicesSection'
import ContactFooter from '../functions/ContactFooter'
import CopyrightsBG from '../functions/CopyrightsBG'

const Services = () => {
    return (
       <div>
        <ServicesHero />
        <ServicesSection />
        <ContactFooter />
        <CopyrightsBG />
       </div>
    )
}

export default Services;
