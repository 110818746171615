import React from "react";
import contactFooterPic from "../img/photos/contact-footer-pic.jpg";
import "../styles/ContactFooter.css";
import { Link } from "react-router-dom";

const ContactFooter = () => {
  return (
    <div className="contact-footer">
      <div className="contact-footer-bg">
        <img src={contactFooterPic} alt="contactFooterPic" />
      </div>

      <div className="footer">
        <ul>
          <li className="nav-item">
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link to="/services">Services</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <span className="line"></span>
      </div>
    </div>
  );
};

export default ContactFooter;
