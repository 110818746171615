import React from 'react'
import '../styles/OtherHero.css'
import servicesVideo from '../videos/bg-video1.mp4'


const ServicesHero = () => {
    return (
        <div className='other-hero'>
            <div className='other-hero-video'>
                <video src={servicesVideo} autoPlay playsInline loop muted />
            </div>
            <div className='other-hero-content'>
                <h1>Leading IT Solutions for Sustainability</h1>
                <p>Quality is essential to us, and that's why we deliver services that fits your business needs.</p>
            </div>
        </div>

        
    )
}

export default ServicesHero;
