import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/HomeHero.css'
import heroVideo from '../videos/bg-video3.mp4'


const HomeHero = () => {
    return (
        <div className='home-hero'>
            <div className='home-hero-video'>
                <video src={heroVideo} autoPlay playsInline loop muted />
            </div>
            <div className='home-hero-content'>
                <h1>Innovate, Connect, Inspire</h1>
                <p>We focus on helping our clients organize their IT investments into a manageable flow of related projects.</p>
                <Link to='/contact'><button className='button'>Contact Us</button></Link>
            </div>
        </div>

        
    )
}

export default HomeHero;
