import React from "react";
import servicePic1 from "../img/photos/Pic2.jpg";
import servicePic2 from "../img/photos/Pic5.jpg";
import servicePic3 from "../img/photos/Pic7.jpg";
import servicePic4 from "../img/photos/Pic1.jpg";
import servicePic5 from "../img/photos/Pic6.jpg";
import servicePic6 from "../img/photos/Pic3.jpg";
import servicePic7 from "../img/photos/Pic4.jpg";

import "../styles/ServicesSection.css";

const Testimonials = () => {
  return (
    <div className="services-section">
      <div className="container">
        <img src={servicePic1} alt="servicePic1" />
        <div className="col-2">
          <h2>Cloud First Agile PMO</h2>
          <span className="line"></span>
          <p>
            PMOs can serve as centers of excellence helping to consolidate the
            Cloud effort and linking the right stakeholder communication
            channels. We follow the Cloud adoption framework which focuses on
            culture as much as the technology.
            <br />
            <br />
            In our experience the Role of the Program Management Office is to be
            center for answering issues with the enterprise approach. Without an
            enterprise approach we may be facing similar issues as other
            deployments like non-uniformity of Systems. We also provide IT
            Governance support and CPIC reporting for all cloud projects.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-2">
          <h2>Business Data Analytics</h2>
          <span className="line"></span>
          <p>
            Business analytics is transforming the way organizations do
            business. As data-driven strategies value proposition increases,
            they will become a key point of competitive differentiation.
            iManagement’s experience with business analytic solutions helps
            organizations of all sizes make sense of information in the context
            of their businesses.
            <br />
            <br />
            You can uncover insights more quickly and more easily from all types
            of data-even big data-and on multiple platforms and devices.
            iManagement helps clients leverage their information assets to find
            business insights that help them run more effective organizations.
            We provide business analytics solutions, including:
            <ul>
              <li>Data management</li>
              <li>Big data and AI builder</li>
              <li>Business intelligence and analytics</li>
              <li>Risk analysis</li>
            </ul>
          </p>
        </div>
        <img src={servicePic2} alt="servicePic2" />
      </div>
      <div className="container">
        <img src={servicePic3} alt="servicePic3" />
        <div className="col-2">
          <h2>Business Process Automation</h2>
          <span className="line"></span>
          <p>
            Our business process analysts delve into the DNA of your business to
            find the true value functions that help your organisation meet its
            goals. With an extensive background in building process libraries,
            business automation and digital transformation, we employ state of
            the art technology to automate complex business processes.
            <br />
            <br />
            We streamline business steps for simplicity, achieve digital
            transformation, increase service quality, improve service delivery,
            and contain costs. We integrate applications, restructure labor
            resources and vet software applications throughout your
            organisation.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-2">
          <h2>Case Management Systems</h2>
          <span className="line"></span>
          <p>
            We develop or modify digital case management systems based on the
            business rules of your organization and then build out a system that
            works best for the needs of your stakeholders.
            <br />
            <br />
            Our analysts have a plethora of experience with various case
            management lifecycle methodologies.
          </p>
        </div>
        <img src={servicePic4} alt="servicePic4" />
      </div>
      <div className="container">
        <img src={servicePic5} alt="servicePic5" />
        <div className="col-2">
          <h2>Learning Management Systems</h2>
          <span className="line"></span>
          <p>
            In today’s age of rapid software development, brain drain from
            retiring workforce and changing compliance rules, it is imperative
            that businesses have an efficient means of upskilling their staff
            and tracking developmental progress.
            <br />
            <br />
            We build Leaning Management Systems (LMS) using industry standard
            authoring tools, and software applications to administer, document,
            track, report and deliver educational courses, training programs, or
            learning and development programs.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="col-2">
          <h2>Document Database</h2>
          <span className="line"></span>
          <p>
            We build fully managed document database applications that are fast,
            scalable, highly available and support multiple workloads. Our
            customers use our solutions as a document database to store,
            retrieve, and manage semi-structured data. Every object, even those
            of the same class, can look very different.
            <br />
            <br />
            Our document stores are similar in that they allow different types
            of documents in a single store, allow the fields within them to be
            optional, and often allow them to be encoded using different
            encoding systems.
          </p>
        </div>
        <img src={servicePic6} alt="servicePic6" />
      </div>
      <div className="container">
        <img src={servicePic7} alt="servicePic7" />
        <div className="col-2">
          <h2>IT Infrastructure Support Service</h2>
          <span className="line"></span>
          <p>
            Our engineers help in managing corporate IT infrastructure to
            maintain high availability, responsiveness, and reliability of the
            IT systems to meet the dynamic needs of the business. We manage the
            hardware, software, systems, and services used by the company to
            support its operations.
            <br />
            <br />
            By outsourcing everyday IT infrastructure administrative and support
            functions to our company, customer’s IT staff can focus on its core
            function increasing efficiency and productivity. Our IT
            infrastructure support service offering include cloud services,
            Network engineering, Network administration, Software Services
            management, Monitoring, and IT security.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
