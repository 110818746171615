import React from 'react'
import HomeHero from '../functions/HomeHero'
import CopyrightsTrans from '../functions/CopyrightsTrans'

function Home() {
  return (
    <div>
      <HomeHero />
      <CopyrightsTrans />
    </div>
  );
}

export default Home;
