import React from 'react'
import ContactHero from '../functions/ContactHero'
import ContactForm from '../functions/ContactForm'
import CopyrightsBG from '../functions/CopyrightsBG'

const Contact = () => {
  return (
    <div>
      <ContactHero />
      <ContactForm />
      <CopyrightsBG />
    </div>
  )
}

export default Contact
