import React from "react";
import "../styles/AboutSection.css";
import aboutPic from "../img/photos/AboutPic.jpg";

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="container">
        <img src={aboutPic} alt="aboutPic" />
        <div className="col-2">
          <h2>iManagement vision statement</h2>
          <p>
            Our goal is to provide our clients with the best IT consulting
            services in the industry. We specialize in project management, data
            analysis, and AI consulting.
            <br />
            <br />
            We are dedicated to delivering innovative solutions that help our
            clients achieve their objectives. Our team of experts provides
            exceptional service and support, and we work closely with our
            clients to ensure that their needs are met. We are passionate about
            what we do, and we are committed to helping our clients succeed.
          </p>
        </div>
      </div>
      <div className="row-2">
        <h2>Contract Vehicles</h2>
        <ul>
          <li>Federal - HUBZone, STARS 8(a) III</li>
          <li>Washington, DC - CBE, LBE, SBE, DBE, ROB</li>
          <li>Maryland - MBE/DBE</li>
        </ul>
        <h2>NAIC Codes</h2>
        <ul>
          <li>
            541511 <span>- Custom Computer Programming Services</span>
          </li>
          <li>
            541512 <span>- Computer Systems Design Services</span>
          </li>
          <li>
            541513 <span>- Computer Facilities Management Services</span>
          </li>
          <li>
            541519 <span>- Other Computer Related Services</span>
          </li>
          <li>
            541611{" "}
            <span>
              - Administrative and General Management Consulting Services
            </span>
          </li>
          <li>
            541618 <span>- Other Management Consulting Services</span>
          </li>
          <li>
            541690{" "}
            <span>- Other Scientific and Technical Consulting Services</span>
          </li>
          <li>
            561110 <span>- Office Administrative Services</span>
          </li>
          <li>
            561210 <span>- Facilities Support Services</span>
          </li>
          <li>
            561320 <span>- Temporary Help Services</span>
          </li>
          <li>
            611430{" "}
            <span>- Professional and Management Development Training</span>
          </li>
          <li>
            611420 <span>- Computer Training</span>
          </li>
          <li>
            561621 <span>- Security Systems Service</span>
          </li>
        </ul>
        <h2>Partners</h2>
        <ul>
          <li>CGI Federal</li>
          <li>LEDIOS</li>
          <li>Northramp</li>
          <li>Teksynap</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutSection;
