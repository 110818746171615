import React from 'react'
import { 
  HashRouter as Router, 
  Routes, 
  Route
} from 'react-router-dom'
import Navbar from './components/functions/Navbar'
import Home from './components/pages/Home'
import About from './components/pages/About'
import Services from './components/pages/Services'
import Contact from './components/pages/Contact'
import NotFound from './components/functions/NotFound'


const Urls = () => {
  return (
    <Router hashType='slash'>
      <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
    </Router>
  )
}

export default Urls
