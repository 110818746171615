import React from 'react'
import '../styles/ContactForm.css'

class ContactForm extends React.Component {
    constructor(props){
      super(props);
      this.state={
        firstName:'',
        lastName:'',
        email:'',
        selectService:'',
        subject:'',
        message:'',
      }
    }
    handleChange=(event)=>{
      this.setState({
        [event.target.name]: event.target.value
      })
    }
    handleSubmit=(event)=>{
      event.preventDefault();
      const data ={
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message
      }
      fetch('https://api.example.com/send/email/contact@example.com',{
        method:'POST',
        body:JSON.stringify(data)
      })
        .then(response=>response.json())
        .then(data => {
          alert('Information sent successfully');
        })
    }
    render(){
      return (
            <div class= "form-container">
                    <form class='form' onSubmit={this.handleSubmit}>
                        <div class="field-align-col">
                            <input type="text" class="form-control" name="firstName" placeholder="First Name" onChange={this.handleChange}/>
                        
                            <input type="text" class="form-control" name="lastName" placeholder="Last Name" onChange={this.handleChange}/>
                        
                            <input type="email" class="form-control" name="email" placeholder="Your Email" onChange={this.handleChange}/>
                        
                            <select class="form-control" name="selectService" onChange={this.handleChange}>
                                <option value="">[Select Service]</option>
                                <option value="">Cloud First Agile PMO</option>
                                <option value="">Business Data Analytics</option>
                                <option value="">Business Process Automation</option>
                                <option value="">Case Management Systems</option>
                                <option value="">Learning Management Systems</option>
                                <option value="">Document Database</option>
                                <option value="">IT Infrastructure Support Service</option>
                            </select>
                        </div>
                        <div className='field-align-row'>
                        
                            <input type="text" class="form-control" name="subject" placeholder="Subject" onChange={this.handleChange}/>
                        
                            <textarea class="form-control" name="message" rows="3" placeholder="Your Message" onChange={this.handleChange}></textarea>
                        
                            <input type="submit" class="button" value="send request" />
                        </div>
                    </form>
            </div>
    );
  }
}

export default ContactForm;
