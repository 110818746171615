import React from 'react'
import '../styles/CopyrightsBG.css'

const CopyrightsBG = () => {
    return (
        <div className='copyrights-bg'>
            <p>&copy; 2023, iManagement. All rights reserved.</p>
        </div>
    )
}

export default CopyrightsBG;
