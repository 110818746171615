import React from 'react'
import '../styles/OtherHero.css'
import aboutVideo from '../videos/bg-video4.mp4'


const AboutHero = () => {
    return (
        <div className='other-hero'>
            <div className='other-hero-video'>
                <video src={aboutVideo} autoPlay playsInline loop muted />
            </div>
            <div className='other-hero-content'>
                <h1>Know Us Better</h1>
                <p>We are comited to building efficiency, delivering value while eliminating waste and saving you time.</p>
            </div>
        </div>

        
    )
}

export default AboutHero;
