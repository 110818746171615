import React from 'react'
import Navbar from './Navbar'

import '../styles/NotFound.css'

const NotFound = () => {
  return (
    <div>
      <Navbar/>
      <div class="content">

            <div class="content-box">

                <div class="big-content">
                    <div class="list-square">
                        <span class="square"></span>
                        <span class="square"></span>
                        <span class="square"></span>
                    </div>

                    <div class="list-line">
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                        <span class="line"></span>
                    </div>

                    <i class="fa fa-search" aria-hidden="true"></i>

                    <div class="clear"></div>

                </div>

                <h1>Oops! Error 404 not found.</h1>

                <p>The page you were looking for doesn't exist.
                    We think the page may have been moved.</p>

            </div>

        </div>

    </div>
  )
}

export default NotFound
