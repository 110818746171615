import React from 'react'
import AboutHero from '../functions/AboutHero'
import AboutSection from '../functions/AboutSection'
import CopyrightsBG from '../functions/CopyrightsBG'

const About = () => {
    return (
       <div>
        <AboutHero />
        <AboutSection />
        <CopyrightsBG />
       </div>
    )
}

export default About
