import React from 'react'
import '../styles/OtherHero.css'
import contactVideo from '../videos/bg-video2.mp4'

const ContactHero = () => {
  return (
    <div className='other-hero'>
            <div className='other-hero-video'>
                <video src={contactVideo} autoPlay playsInline loop muted />
            </div>
            <div className='other-hero-content'>
                <h1>Contact Us</h1>
                <p>Request more information from our team of experts. We are always happy to hear from you.</p>
            </div>
    </div>
  )
}

export default ContactHero;